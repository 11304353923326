import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth-store";
const SideMenu = () => import("../layouts/side-menu/Main.vue");
const Page1 = () => import("../views/page-1/Main.vue");
const Page2 = () => import("../views/page-2/Main.vue");
const PageGames = () => import("../views/page-games/Main.vue");
const PageBreeds = () => import("../views/page-breeds/Main.vue");
const PageInterests = () => import("../views/page-interests/Main.vue");
const PageChallanges = () => import("../views/page-challanges/Main.vue");
const PageCharacteristics = () =>
  import("../views/page-characteristics/Main.vue");
const PageRecipes = () => import("../views/page-recipes/Main.vue");
const PageCourses = () => import("../views/page-courses/Main.vue");
const PageTasks = () => import("../views/page-tasks/Main.vue");
const PageHelpSupport = () => import("../views/page-help-support/Main.vue");
const PageQuizzes = () => import("../views/page-quizzes/Main.vue");
const AddTrick = () => import("../views/add-trick/Main.vue");
const AddTask = () => import("../views/add-task/Main.vue");
const AddHelpSupport = () => import("../views/add-help-support/Main.vue");
const AddQuizze = () => import("../views/add-quizze/Main.vue");
const AddCourse = () => import("../views/add-course/Main.vue");
const AddLesson = () => import("../views/add-course-lesson/Main.vue");
const AddExam = () => import("../views/add-course-exam/Main.vue");
const AddFaq = () => import("../views/add-course-lesson-faq/Main.vue");
const AddRecipe = () => import("../views/add-recipe/Main.vue");
const AddGame = () => import("../views/add-game/Main.vue");
const AddBreed = () => import("../views/add-breed/Main.vue");
const AddInterest = () => import("../views/add-interest/Main.vue");
const PageAppUsers = () => import("../views/page-app-users/Main.vue");
const PageFeedback = () => import("../views/page-feedback/Main.vue");
const AddChallange = () => import("../views/add-challange/Main.vue");
const AddCharacteristic = () => import("../views/add-characteristic/Main.vue");
const AddTrickDetail = () => import("../views/add-trick-detail/Main.vue");
const AddCourseLessonDetail = () =>
  import("../views/add-course-lesson-detail/Main.vue");
const AddGameDetail = () => import("../views/add-game-detail/Main.vue");
const DevSettings = () => import("../views/page-dev/Main.vue");
const Login = () => import("../views/login/Main.vue");
const Profile = () => import("../views/profile/Main.vue");
const PageUsers = () => import("../views/page-users/Main.vue");
const UserForm = () => import("../views/page-user-form/Main.vue");
import auth from "@/services/auth";
const ErrorPage = () => import("../views/error-page/Main.vue");
const PageDiscover = () => import("../views/page-discovery/Main.vue");
const AddDiscover = () => import("../views/add-discovery/Main.vue");
const AddDiscoverDetail = () =>
  import("../views/add-discovery-detail/Main.vue");
const AddAppUser = () => import("../views/add-app-users/Main.vue");
const PageImagify = () => import("../views/page-imagify/Main.vue");
const AddAppUserDog = () => import("../views/add-app-users-dog/Main.vue");
const PageEmail = () => import("../views/page-email/Main.vue");
const PageEmails = () => import("../views/page-emails/Main.vue");
const PageTranslations = () => import("../views/page-translations/Main.vue");
const PageAppTranslations = () =>
  import("../views/page-app-translations/Main.vue");
const EditTranslation = () => import("../views/edit-translation/Main.vue");
const Help = () => import("../views/help/Main.vue");
const PageGuides = () => import("../views/page-guides/Main.vue");
const AddGuide = () => import("../views/add-guide/Main.vue");
const PromosPage = () => import("../views/page-promos/Main.vue");
const AddPromo = () => import("../views/add-promo/Main.vue");
const PageTrainingsPlan = () =>
  import("../views/page-trainings-plans/Main.vue");
const AddTrainingsPlan = () => import("../views/add-trainings-plan/Main.vue");
const MediaManager = () => import("../views/media-manager/Main.vue");
const MissingTranslations = () =>
  import("../views/missing-translations/Main.vue");
const KIPage = () => import("../views/ki-page/Main.vue");
const PageAudios = () => import("../views/page-audios/Main.vue");
const AddAudio = () => import("../views/add-audio/Main.vue");
const PageTranslationJob = () =>
  import("../views/page-translation-job/Main.vue");
const PageTranslationTask = () =>
  import("../views/page-translation-task/Main.vue");
const AddStartingMessage = () =>
  import("../views/add-ki-starting-message/Main.vue");
const PageTTS = () => import("../views/page-tts/Main.vue");
const PageTTSJob = () => import("../views/page-tts-job/Main.vue");
const PageFeedbackReasons = () =>
  import("../views/page-feedback-reasons/Main.vue");
const AddFeedbackReason = () => import("../views/add-feedback-reason/Main.vue");
const AddPushTemplate = () => import("../views/add-push-template/Main.vue");
const PagePushTemplates = () => import("../views/page-push-templates/Main.vue");
const PageJourneys = () => import("../views/page-journeys/Main.vue");
const AddJourney = () => import("../views/add-journey/Main.vue");
const PageInstantMessages = () =>
  import("../views/page-instant-messages/Main.vue");
const AddInstantMessage = () => import("../views/add-instant-message/Main.vue");

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Page1,
      },
      {
        path: "/help/:name?",
        name: "Help",
        component: Help,
      },
      {
        path: "/tts",
        name: "TTS",
        component: PageTTS,
      },
      {
        path: "/media-manager",
        name: "MediaManager",
        component: MediaManager,
      },
      {
        path: "/translations",
        name: "Translations",
        component: PageTranslations,
      },
      {
        path: "/app-translations",
        name: "App Übersetzungen",
        component: PageAppTranslations,
      },
      {
        path: "/missing-translations",
        name: "MissingTranslations",
        component: MissingTranslations,
      },
      {
        path: "/translation-job/:id",
        name: "TranslationJob",
        component: PageTranslationJob,
      },
      {
        path: "/tts-job/:id",
        name: "TTSJob",
        component: PageTTSJob,
      },
      {
        path: "/translation-task-detail/:id",
        name: "TranslationTask",
        component: PageTranslationTask,
      },
      {
        path: "/edit-lang/:lang",
        name: "Edit Translation",
        component: EditTranslation,
      },
      {
        path: "/add-mail/:id?",
        name: "Email",
        component: PageEmail,
      },
      {
        path: "/add-starting-message/:id?",
        name: "Add Starting Message",
        component: AddStartingMessage,
      },
      {
        path: "/emails",
        name: "Emails",
        component: PageEmails,
      },
      {
        path: "/imagify",
        name: "Imagify",
        component: PageImagify,
      },
      {
        path: "page-2",
        name: "Tricks",
        component: Page2,
      },
      {
        path: "page-games",
        name: "Spiele",
        component: PageGames,
      },
      {
        path: "page-audios",
        name: "Audios",
        component: PageAudios,
      },
      {
        path: "page-quizzes",
        name: "Quizze",
        component: PageQuizzes,
      },
      {
        path: "page-courses",
        name: "Kurse",
        component: PageCourses,
      },
      {
        path: "page-tasks",
        name: "Tasks",
        component: PageTasks,
      },
      {
        path: "page-promos",
        name: "Promos",
        component: PromosPage,
      },
      {
        path: "page-push-templates",
        name: "Push Templates",
        component: PagePushTemplates,
      },
      {
        path: "add-push-template/:id?",
        name: "Push Template",
        component: AddPushTemplate,
      },
      {
        path: "page-trainings-plan",
        name: "Trainings Plan",
        component: PageTrainingsPlan,
      },
      {
        path: "page-help-support",
        name: "Hilfe/Support",
        component: PageHelpSupport,
      },
      {
        path: "page-feedback-reasons",
        name: "Feedback Gründe",
        component: PageFeedbackReasons,
      },
      {
        path: "add-feedback-reason/:id?",
        name: "Feedback Grund",
        component: AddFeedbackReason,
      },
      {
        path: "add-courses-lesson/:courseID/:lessonID?",
        name: "Lektion hinzufügen",
        component: AddLesson,
      },
      {
        path: "add-courses-exam/:courseID/:examID?",
        name: "Prüfung hinzufügen",
        component: AddExam,
      },
      {
        path: "add-course-lesson-detail/:lessonID/:detailID?",
        name: "Lektion Anleitungsschritte Bearbeiten",
        component: AddCourseLessonDetail,
      },
      {
        path: "add-course-lesson-faq/:lessonID/:faqID?",
        name: "FAQ Bearbeiten",
        component: AddFaq,
      },
      {
        path: "add-app-user-dog/:userID/:dogID?",
        name: "Hund Bearbeiten",
        component: AddAppUserDog,
      },
      {
        path: "page-recipes",
        name: "Rezepte",
        component: PageRecipes,
      },
      {
        path: "page-breeds",
        name: "Rassen",
        component: PageBreeds,
      },
      {
        path: "page-interests",
        name: "Interessen",
        component: PageInterests,
      },
      {
        path: "page-app-users",
        name: "App Benutzer",
        component: PageAppUsers,
      },
      {
        path: "page-feedback/:id?",
        name: "Feedback",
        component: PageFeedback,
      },
      {
        path: "page-guides",
        name: "Guides",
        component: PageGuides,
      },
      {
        path: "page-challanges",
        name: "Herausforderungen",
        component: PageChallanges,
      },
      {
        path: "page-discovers",
        name: "Entdecken",
        component: PageDiscover,
      },
      {
        path: "page-characteristics",
        name: "Eigenschaften",
        component: PageCharacteristics,
      },
      {
        path: "add-trick/:id?",
        name: "Trick Bearbeiten",
        component: AddTrick,
      },
      {
        path: "add-audio/:id?",
        name: "Audio Bearbeiten",
        component: AddAudio,
      },
      {
        path: "add-trainings-plan/:id?",
        name: "Trainings Plan Bearbeiten",
        component: AddTrainingsPlan,
      },
      {
        path: "add-promo/:id?",
        name: "Promo Bearbeiten",
        component: AddPromo,
      },
      {
        path: "add-guide/:id?",
        name: "Guide Bearbeiten",
        component: AddGuide,
      },
      {
        path: "add-task/:id?",
        name: "Task Bearbeiten",
        component: AddTask,
      },
      {
        path: "add-help-support/:id?",
        name: "Hilfe/Support Bearbeiten",
        component: AddHelpSupport,
      },
      {
        path: "add-quizze/:id?",
        name: "Quizze Bearbeiten",
        component: AddQuizze,
      },
      {
        path: "add-discover/:id?",
        name: "Entdecken Bearbeiten",
        component: AddDiscover,
      },
      {
        path: "add-discover-detail/:discoverID/:id?",
        name: "Entdecken Detail Bearbeiten",
        component: AddDiscoverDetail,
      },
      {
        path: "add-game/:id?",
        name: "Spiel Bearbeiten",
        component: AddGame,
      },
      {
        path: "add-app-users/:id?",
        name: "Benutzer Bearbeiten",
        component: AddAppUser,
      },
      {
        path: "add-breed/:id?",
        name: "Rasse Bearbeiten",
        component: AddBreed,
      },
      {
        path: "add-interest/:id?",
        name: "Interesse Bearbeiten",
        component: AddInterest,
      },
      {
        path: "add-course/:id?",
        name: "Kurs Bearbeiten",
        component: AddCourse,
      },
      {
        path: "add-challange/:id?",
        name: "Herausforderung Bearbeiten",
        component: AddChallange,
      },
      {
        path: "add-characteristic/:id?",
        name: "Eigenschaften Bearbeiten",
        component: AddCharacteristic,
      },
      {
        path: "add-recipe/:id?",
        name: "Rezept Bearbeiten",
        component: AddRecipe,
      },
      {
        path: "add-trick-detail/:trickID/:detailID?",
        name: "Trick Anleitungsschritte Bearbeiten",
        component: AddTrickDetail,
      },
      {
        path: "add-game-detail/:gameID/:detailID?",
        name: "Spiel Anleitungsschritte Bearbeiten",
        component: AddGameDetail,
      },
      {
        path: "dev-page",
        name: "DEV",
        component: DevSettings,
      },
      {
        path: "profile/:id?",
        name: "Profile",
        component: Profile,
      },
      {
        path: "users",
        name: "Benutzer",
        component: PageUsers,
      },
      {
        path: "user-form",
        name: "Nutzer Bearbeiten",
        component: UserForm,
      },
      {
        path: "ki-page",
        name: "KI",
        component: KIPage,
      },
      {
        path: "page-journeys",
        name: "Journeys",
        component: PageJourneys,
      },
      {
        path: "add-journey/:id?",
        name: "Journey Bearbeiten",
        component: AddJourney,
      },
      {
        path: "page-instant-messages",
        name: "Instant Messages",
        component: PageInstantMessages,
      },
      {
        path: "add-instant-message/:id?",
        name: "Instant Message Bearbeiten",
        component: AddInstantMessage,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  const loggedIn = await auth.verify();
  const authStore = useAuthStore();

  if (
    to.name == "DEV" &&
    !authStore.user?.Role.Permissions.some((e) => e.key == "dev")
  ) {
    return { name: "Dashboard" };
  }

  if (
    to.name == "Imagify" &&
    !authStore.user?.Role.Permissions.some((e) => e.key == "imagify")
  ) {
    return { name: "Dashboard" };
  }

  if (
    to.name == "Benutzer" &&
    !authStore.user?.Role.Permissions.some((e) => e.key == "view_users")
  ) {
    return { name: "Dashboard" };
  }

  if (
    to.name == "App Benutzer" &&
    !authStore.user?.Role.Permissions.some((e) => e.key == "view_app_users")
  ) {
    return { name: "Dashboard" };
  }

  if (
    to.name == "Feedback" &&
    !authStore.user?.Role.Permissions.some((e) => e.key == "feedback")
  ) {
    return { name: "Dashboard" };
  }

  if (loggedIn && to.name == "login") {
    return { name: "Dashboard" };
  }

  if (loggedIn) {
    return true;
  }

  if (to.name === "login") {
    return true;
  }
  authStore.returnUrl = to.fullPath;
  return { name: "login" };
});

export default router;
